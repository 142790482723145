export const formatAmeritas = (values: string) => {
  if (!values) return [];

  return (
    values
      ?.replace(/,/g, '')
      .split('\n')
      .map((value) =>
        value
          ?.replace(/\s+/g, ' ')
          ?.trim()
          ?.split(' ')
          .map((value) => parseFloat(value)),
      ) ?? []
  );
};
