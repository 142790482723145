import { ComponentProps } from 'react';

import { styled } from '~/stitches.config';

export const Textarea = styled('textarea', {
  outline: 'none',
  display: 'block',
  backgroundColor: '$gray100',
  width: '100%',
  maxWidth: '100%',
  border: 0,
  borderRadius: 4,
  p: 0,
  fontFamily: '$inter',
  fontWeight: '$regular',
  boxSizing: 'border-box',

  '&:focus': {
    boxShadow: '0 0 0 2px $colors$blue800',
  },

  variants: {
    size: {
      large: {
        minHeight: 48,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 24,
        paddingRight: 24,
        fontSize: 18,
      },
      medium: {
        minHeight: 40,
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 24,
        paddingRight: 24,
        fontSize: 16,
      },
      small: {
        minHeight: 32,
        height: 'auto',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 14,
      },
    },
  },

  defaultVariants: {
    size: 'small',
  },
});

export type TextareaProps = ComponentProps<typeof Textarea>;
