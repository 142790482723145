import React, { useEffect, useState } from 'react';

import { CarrierForm } from '~/components/Forms/CarrierForm';
import { Box } from '~/components/Primitives/Box';
import { Card } from '~/components/Primitives/Card';
import { Container } from '~/components/Primitives/Container';
import { FormTable } from '~/components/Calculator/FormTable';
import { Routes } from '~/constants/routes';
import { CalculatorLayout } from '~/layouts/CalculatorLayout';
import { TableSettings } from '~/components/TableSettings';

export const getStaticProps = () => {
  return {
    props: {
      layout: {
        currentStep: 'Step 1: Form',
        nextStep: Routes.calculator.credit,
      },
    },
  };
};

export default function CalculatorFormPage() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <Container css={{ position: 'relative', mt: 56 }}>
        <Box
          css={{
            display: 'grid',
            gridTemplateColumns: '300px 1fr',
            alignItems: 'start',
            columnGap: '32px',
            height: '100%',
            pb: 96,
          }}
        >
          <Card css={{ position: 'sticky', top: 104, width: 300, p: 24 }}>
            <CarrierForm />
            <TableSettings />
          </Card>
          <Card css={{ p: 24, overflow: 'auto' }}>{isMounted && <FormTable />}</Card>
        </Box>
      </Container>
    </>
  );
}

CalculatorFormPage.layout = CalculatorLayout;
