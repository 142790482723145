import React, { useMemo } from 'react';
import { Table } from '~/components/Primitives/Table';
import { Text } from '~/components/Primitives/Text';

import { useCalculatorValues } from '~/hooks/useCalculatorValues';
import { useTableSettings } from '~/hooks/useTableSettings';
import { calculateCost, calculateCostYear, calculateNoCost } from '~/utils/calculations';
import { splitArraysIntoChunks } from '~/utils/splitArraysIntoChunks';

export const FormTable = () => {
  const [tableSettings] = useTableSettings();
  const [calculatorData] = useCalculatorValues();

  const columns = useMemo(
    () => [
      {
        Header: 'Year',
        accessor: 'year',
        width: 60,
      },
      {
        Header: 'Age',
        accessor: 'age',
        width: 60,
      },
      {
        Header: 'Premium',
        accessor: 'premium',
        width: 120,
      },
      {
        Header: 'Cash value',
        accessor: 'cashValue',
        width: 160,
      },
      {
        Header: 'Death benefits',
        accessor: 'deathBenefits',
        width: 160,
      },
      {
        Header: 'No cost',
        accessor: 'noCost',
        width: 160,
      },
      {
        Header: 'MAR',
        accessor: 'mar',
        width: 160,
      },
      {
        Header: 'Cost',
        accessor: 'cost',
        width: 160,
      },
      {
        Header: 'Cost / Year',
        accessor: 'costPerYear',
        width: 160,
      },
    ],
    [],
  );

  const data = useMemo(() => {
    const inputData = calculatorData.form.input;
    const formattedData = calculatorData.form.formatted ?? [];

    return (
      formattedData.map((value, index) => {
        return {
          ...value,
          cashValue: value.cashValue?.toLocaleString(),
          deathBenefits: value.deathBenefits?.toLocaleString(),
          premium: value.premium?.toLocaleString(),
          age: value.age?.toLocaleString(),
          year: value.year?.toLocaleString(),
          noCost: calculateNoCost(
            index + 1,
            value.premium,
            parseFloat(inputData.loanPercentage),
            formattedData.map((row) => row.premium),
          )?.toLocaleString(),
          mar: (value.deathBenefits - value.premium)?.toLocaleString(),
          cost: calculateCost(
            value.premium,
            calculateNoCost(
              index + 1,
              value.premium,
              parseFloat(inputData.loanPercentage),
              formattedData.map((row) => row.premium),
            ),
          )?.toLocaleString(),
          costPerYear: calculateCostYear(
            index + 1,
            value.premium,
            parseFloat(inputData.loanPercentage),
            formattedData.map((row) => row.premium),
            formattedData.map((row) => row.premium),
          )?.toLocaleString(),
        };
      }) ?? []
    );
  }, [calculatorData]);

  if (tableSettings.splitEvery10Rows) {
    const groups = splitArraysIntoChunks(data);

    return (
      <>
        {groups.map((group, index) => {
          const isLastGroup = groups.length - 1 === index;
          const currentYear = group[0].year;

          return (
            <>
              <Text as='h3' css={{ fontWeight: '$semibold', mb: 12 }} size='body-12'>
                Year {currentYear}
                {isLastGroup ? '+' : ` - ${parseFloat(currentYear) + 9}`}
              </Text>
              <Table columns={columns} css={{ mb: 24 }} data={group} />
            </>
          );
        })}
      </>
    );
  }

  return <Table data={data} columns={columns} />;
};
