import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { analytics } from '~/analytics/analytics';

import { Box } from '~/components/Primitives/Box';
import { Button } from '~/components/Primitives/Button';
import { Input } from '~/components/Primitives/Input';
import { Text } from '~/components/Primitives/Text';
import { Textarea } from '~/components/Primitives/Textarea';
import { INITIAL_VALUES, useCalculatorValues } from '~/hooks/useCalculatorValues';
import { formatAmeritas } from '~/utils/formatAmeritas';

export const CarrierForm = () => {
  const { addToast } = useToasts();
  const [calculatorValues, setCalculatorValues] = useCalculatorValues();

  return (
    <Formik
      enableReinitialize
      initialValues={calculatorValues?.form?.input || INITIAL_VALUES.form.input}
      onSubmit={(values, { setSubmitting }) => {
        const formattedData = formatAmeritas(values.ameritas);

        const sortedData = formattedData.map((row) => ({
          year: row[1],
          age: row[0],
          premium: row[2],
          cashValue: row[3],
          deathBenefits: row[5],
        }));

        setCalculatorValues({
          ...calculatorValues,
          form: {
            input: values,
            formatted: sortedData,
          },
        });

        analytics.track('Submit form', {
          parentComponent: 'CarrierForm',
          values,
        });

        addToast('Successfully calculated data from carrier.');

        setSubmitting(false);
      }}
    >
      {({ handleReset }) => (
        <Form>
          <Box css={{ mb: 24 }}>
            <Text
              as='label'
              css={{ display: 'inline-block', mb: 4, fontWeight: '$medium' }}
              htmlFor='values'
              size='body-12'
            >
              Values
            </Text>
            <Text as='p' css={{ mb: 8, color: '$gray600' }} size='body-12'>
              Copy and paste the values from carrier
            </Text>
            <Field
              as={Textarea}
              css={{ minHeight: 240, fontSize: 10 }}
              id='ameritas'
              name='ameritas'
              min={0}
              placeholder='Paste here'
            />
          </Box>

          <Box css={{ mb: 24 }}>
            <Text
              as='label'
              css={{ display: 'inline-block', mb: 4, fontWeight: '$medium' }}
              htmlFor='loanPercentage'
              size='body-12'
            >
              Illustration %
            </Text>
            <Field
              as={Input}
              id='loanPercentage'
              name='loanPercentage'
              min={0}
              placeholder='Loan %'
              step={0.01}
              type='number'
            />
          </Box>

          <Button
            css={{ width: '100%', mb: 12 }}
            onClick={() => analytics.track('Clicked button', { parentComponent: 'CarrierForm', label: 'Calculate' })}
            type='submit'
          >
            Calculate
          </Button>

          <Button
            appearance='secondary'
            css={{ width: '100%' }}
            onClick={() => {
              setCalculatorValues((old) => ({
                ...old,
                form: INITIAL_VALUES.form,
              }));

              analytics.track('Clicked button', { parentComponent: 'CarrierForm', label: 'Reset' });

              handleReset();
            }}
            type='button'
          >
            Reset
          </Button>
        </Form>
      )}
    </Formik>
  );
};
